import React, { Component } from 'react';
import './footer.less';

class Footer extends Component {
  render() {
    return (
      <div className="footer">&copy;SMS 2019 </div>
    )
  }
}

export default Footer;