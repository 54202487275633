import React, { Component } from 'react';

class NotFound extends Component {
  render() {
    return (
      <div>not found</div>
    )
  }
}

export default NotFound;