import React, { Component } from 'react';
import { Icon } from 'antd';
import './loading.less';

class Loading extends Component {
  render() {
    return (
      <div className="loading"><Icon type="loading" /></div>
    )
  }
}

export default Loading;