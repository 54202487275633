export const userDesc = {
  gender: {
    1: 'Male',
    2: 'Female',
    3: 'Other'
  },
  ethnicity: {
    1: 'Asian',
    2: 'Black/African',
    3: 'Indian',
    4: 'Latino/Hispanic',
    5: 'Middle eastern',
    6: 'White/Caucasian',
    7: 'Other'
  },
  bodyType: {
    1: 'Slim',
    2: 'Athletic',
    3: 'Stout',
    4: 'Average'
  },
  children: {
    1: 'No children',
    4: 'Child not at home',
    3: 'Children at home'
  },
  relationship: {
    1: 'Nerver Married',
    2: 'Seperated Divorced',
    3: 'Widowed',
    4: 'Tell you later'
  },
  smoke: {
    1: 'Don\'t',
    2: 'Smoke Socially',
    3: 'Smoke Regularly'
  },
  drink: {
    1: 'Don\'t',
    2: 'Smoke Socially',
    3: 'Smoke Regularly'
  },
  religion: {
    1: 'Agnostaic',
    2: 'Atheist',
    3: 'Buddhist',
    4: 'Christian',
    5: 'Catholic',
    6: 'Hindu',
    7: 'Jewish',
    8: 'Muslim',
    9: 'Spiritual',
    10: 'Other'
  },
  education: {
    1: 'No degree',
    2: 'Hign school',
    3: 'Attended college',
    4: 'College graduate',
    5: 'Advanced degree'
  },
  workin: {
    1: 'Arts/Music/Writing',
    2: 'Banking/Finance Business',
    3: 'Management',
    4: 'Construction',
    5: 'Education',
    6: 'Entertainment/Media',
  },
  income: {
    1: 'No answer',
    2: 'Less than $30000',
    3: '#30,001 to $50,000',
    4: '$50,001 to $70,000',
    5: '$100,001 to $150,000',
    6: '$15,001 to $200,000',
    7: 'More than $200,001'
  },
  activeStatus: {
    1: 'Online',
    2: 'Offline',
    3: 'Invisible'
  },
  status: {
    '-1': 'Invalid',
    '0': 'Effective'
  }
}

export const pageConfig = {
  limit: 10,
  page: 1
}

export const ROLES = {
  'admin': 3,
  'manager': 2,
  'user': 1
}

// export const ROLES = ['superadmin', 'admin', 'ordinary']