const en_US = {
  // common
  'common.header.title': 'SMS',
  'common.btn.ok': 'Ok',
  'common.btn.cancel': 'Cancel',
  'common.btn.action': 'Action',
  'common.btn.refund': 'Refund',
  'common.btn.unsubscribe': 'Unsubscribe',
  'common.btn.search': 'Search',
  'common.btn.enable': 'Enable',
  'common.btn.disable': 'Prohibit',
  'common.btn.create': 'Create',
  'common.btn.edit': 'Edit',
  'common.btn.save': 'Save',
  'common.btn.login': 'Sign in',
  'common.btn.logout': 'Logout',
  'common.btn.saveAndSent': 'Save And Send',
  'common.btn.send': 'Send',
  'common.btn.publish': 'Publish',
  'common.btn.stopPub': 'Stop Publishing',
  'common.btn.saveAndPub': 'Save And Publish',
  'common.btn.back': 'Go Back',
  'common.btn.verify': 'Verify',
  'common.btn.unverify': 'Unverify',
  'common.label.status': 'Status',
  'common.text.yes': 'Y',
  'common.text.no': 'N',
  'common.btn.setting': 'Setting',
  'common.btn.delete': 'Delete',

  // aside
  'aside.home': 'Home',
  'aside.hobby': 'Hobby',
  'aside.payrecord': 'Pay record',
  'aside.user': 'User',
  'aside.notice': 'Notice',
  'aside.statistics': 'Statistics',
  'aside.userList': 'User List',
  'aside.userEdit': 'User Edit',
  'aside.adminList': 'Admin Account',
  'aside.advert': 'Advertisement',
  'aside.report': 'Report',
  'aside.cancelRecord': 'Refund record',
  'aside.title': 'Dashboard',
  'aside.sp': 'Numbers',
  'aside.forget': 'Modify Password',
  'aside.users': 'Users', 
  'aside.messages': 'Messages',
  'aside.settings': 'Settings',
  // user
  'user.label.username': 'UserName',
  'user.label.loginName': 'LoginName',
  'user.label.phone': 'Number',
  'user.label.manager': 'Manager Owner',
  'user.label.owner': 'User Owner',
  'user.label.invalidDate': 'Valid before',
  'user.label.status': 'Status',
  'user.label.role': 'Role',
  'user.label.parent': 'Manager',
  'user.label.contact': 'Contact',
  'user.btn.allocNumber': 'Alloc Number',
  'common.btn.resetPasswd': 'Reset Password',
  'user.btn.createUser': 'Create User'
  

}
export default en_US;