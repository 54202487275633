import React, { Component } from 'react';
import pathToRegexp from 'path-to-regexp';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import NotFound from './components/common/notFound'
import Routers from './router/index'
import Layout from './components/common/layout'
import { getUserInfo } from './utils/util'
import './app.less'

const defaultPath = ['/', '/mine']
const defaultMenu = '/mine/hobby/list'

class App extends Component {

  state = {
    showGoTop: true,
    isMine: null
  }

  componentWillMount() {
    const pathname = window.location.pathname
    let isMine = null
    // if (pathname.startsWith('/mine') || pathname === '/') {
    isMine = this.needAuth(pathname)
    // }
    this.setState({
      isMine
    })
  }

  componentDidMount() {
  }

  needAuth(pathname) {
    const userInfo = getUserInfo()
    if (!userInfo) {
      return false
    }
    const needArr = Routers.filter(router => {
      if (router.match) {
        if (defaultPath.indexOf(pathname) > -1) {
          pathname = defaultMenu
        } else if (pathname.startsWith('/mine/user/create/')) {
          pathname = '/mine/user/admin'
        } else if (pathname.startsWith('/mine/notice/create/')) {
          pathname = '/mine/notice'
        } else if (pathname.startsWith('/mine/advert/create/')) {
          pathname = '/mine/advert'
        } else if (pathname.startsWith('/mine/user/detail/')) {
          pathname = '/mine/user'
        }
        return pathToRegexp(router.match).exec(pathname)
      } else {
        return router.path === pathname
      }
    })

    if (needArr && needArr.length) {
      return needArr[0].needAuth;
    }
    return false;
  }

  checkMenu(pathname) {
    const isMine = pathname
    this.setState({
      isMine
    })
  }

  render() {
    const userInfo = getUserInfo()
    const token = userInfo && userInfo.token
    const { isMine } = this.state
    return (
      <BrowserRouter>
        <div
          className='app'
        >
          <div className="main">
            <Layout header={isMine} footer={isMine} aside={false}>
              <Switch>
                {
                  Routers.map((item, index) => {
                    return <Route key={index} path={item.path} exact render={props =>
                      (
                        !item.needAuth ? (<item.component {...props} />)
                          :
                          (token ? <item.component {...props} />
                            :
                            <Redirect to={{
                              pathname: '/login',
                              state: { from: props.location }
                            }} />
                          ))} />
                  })
                }
                <Route component={NotFound} />
              </Switch>
            </Layout>
          </div>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
