import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Popconfirm, Icon } from 'antd';
import { getUserInfo, clearLocal } from '../../../utils/util';
// import { logout } from '../../../views/user/api';
import './header.less';

class Header extends Component {

  state = {
    logined: false,
    userInfo: null
  }

  componentDidMount() {
    const userInfo = getUserInfo();
    if (userInfo && userInfo.token) {
      this.setState({
        logined: true,
        userInfo
      })
    }
  }

  handleLogout() {
    // logout().then(res => {
    //   if (res && res.code === '000000') {
    //     clearLocal()
    //     window.location = '/login'
    //   }
    // })
  }

  render() {
    const { logined, userInfo } = this.state;
    return (
      <div className="header">
        <div className="op">
          {
            logined && userInfo ? (
              <>
                <span>Welcome, {userInfo.username}</span>
                {/* <Popconfirm
                  placement="bottom"
                  title="Are you sure？"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={this.handleLogout.bind(this)}
                >
                  <a href="javascript:void(0)">
                    <FormattedMessage id="common.btn.logout" />
                  </a>
                </Popconfirm> */}
              </>
            ) : null
          }
        </div>
      </div>
    )
  }
}

export default Header;