import { ROLES } from '../constant';

export const getRealPhoneNum = (phone) => {
	if(!phone) {
		return phone;
	}
    let newPhone = phone.replace(/ /g, '');
	if(newPhone.length > 9) {
		newPhone = phone.substring(newPhone.length - 9, newPhone.length);
	}

	return newPhone;
}
export const translateParams = (params) => {
  let paramString = '';
  if (params) {
    const validParams = Object.keys(params).filter((key) => params[key]);
    paramString = validParams.map((key) => {
      return key + '=' + encodeURIComponent(params[key]);
    }).join('&');
  }
  return paramString;
}
export const getParamsObj = (href) => {
  let url = href ? href : window.location.href;
  let obj = {};
  let reg = /\?/;
  if (url.match(reg)) {
    let chars = url.split('?')[1];
    let arr = chars.split('&');
    for (let i = 0; i < arr.length; i++) {
      let num = arr[i].indexOf("=");
      if (num > 0) {
        let name = arr[i].substring(0, num);
        let value = arr[i].substr(num + 1);
        obj[decodeURIComponent(name)] = decodeURIComponent(value);
      }
    }
  }
  return obj
}

export const getUserInfo = () => {
  let userInfo = localStorage.getItem('userInfo')
  if (userInfo) {
    return JSON.parse(userInfo);
  }
  return null;
}

export const saveUserInfo = (userInfo) => {
  if (userInfo) {
    localStorage.setItem('userInfo', JSON.stringify(userInfo))
  }
}

export const clearLocal = () => {
  localStorage.clear()
}

export const hasAuth = (authority) => {
  let role = ROLES[authority];
  
  const user = getUserInfo();
  if (user) {
    if (user.role === role) {
      return true;
    }
  }
  return false;
}