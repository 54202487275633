import Loadable from 'react-loadable';
import Loading from '../components/common/loading';
// import Lobby from '../views/carowner/lobby'
const delay = 300
const timeout = 10000
// 首页
const Home = Loadable({
  loader: () => import('../views/home'),
  loading: Loading,
  delay,
  timeout,
});

// Login
const Login = Loadable({
  loader: () => import('../views/login'),
  loading: Loading,
  delay,
  timeout,
});


const Forbidden = Loadable({
  loader: () => import('../components/common/forbidden'),
  loading: Loading,
  delay,
  timeout,
});


export default [
  { path: "/", name: "Home", component: Home, needAuth: true },
  { path: "/login", name: "Login", component: Login },
  { path: "/forbidden", name: "Forbidden", component: Forbidden },
]