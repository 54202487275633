import React, { Component } from 'react';
import Header from '../header';
import Footer from '../footer';
import Aside from '../aside';
import './layout.less';

class Layout extends Component {
  render() {
    const { footer, header, aside } = this.props;
    return (
      <div className="layout">
        {
          header && <Header />
        }
        <div className="layout_body">
          <div>
            {
              aside && <Aside />
            }
            <div className="content">
              {
                this.props.children
              }
            </div>
          </div>
        </div>
        {
          // footer && <Footer />
        }
      </div>
    )
  }
}

export default Layout;