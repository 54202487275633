
const TEST = 'TEST'
const ADD = 'ADD'
const initState = {
  testData: 'test',
  num: 0
}

export function user(state = initState, action) {
  switch (action.type) {
    case ADD:
      return { ...state, num: state.num + action.payload }
    default:
      return state
  }
}

export const add = () => {
  return { type: ADD, payload: 1 }
}

export const addAsync = () => {
  return (dispatch, getState) => {
    setTimeout(() => {
      dispatch(add())
    }, 1000)
  }
}

