import React from 'react'
import ReactDOM from 'react-dom'
import { addLocaleData, IntlProvider } from 'react-intl'
import en from 'react-intl/locale-data/en';
import zh from 'react-intl/locale-data/zh';
// import zhCN from './assets/locale/zh_CN';
import enUS from './assets/locale/en_US';
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import reducers from './reducers'
import App from './App'
import './index.less'
const store = createStore(reducers, compose(
  applyMiddleware(thunk),
  window.devToolsExtension ? window.devToolsExtension() : f => f
))

addLocaleData([...zh, ...en]);

ReactDOM.render(
  (<IntlProvider locale='en' messages={enUS}>
    <Provider store={store}>
      <App />
    </Provider>
  </IntlProvider>),

  document.getElementById('root'))
