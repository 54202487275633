import React, { Component } from 'react';
import { Icon } from 'antd';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import pathToRegexp from 'path-to-regexp';
import { hasAuth } from '../../../utils/util';
import './aside.less';


const defaultPath = ['/', '/mine']
const defaultMenu = '/mine/hobby/list'

class Header extends Component {

  constructor(props) {
    super(props);
    this.state = {
      menus: [],
      currentMenu: 'hobby',
      openMenu: ''
    }
  }

  componentWillReceiveProps(nextProps) {
    let pathname = window.location.pathname
    this.setCurrent(nextProps.intl, pathname)
  }
  componentWillMount() {
    const { intl } = this.props;
    let pathname = window.location.pathname
    this.setCurrent(intl, pathname)
  }

  getMenus(intl) {
    const menus = [
      {
        key: 'home',
        name: intl.formatMessage({ id: 'aside.sp' }),
        url: '/',
        icon: 'home'
      },
    ];
    return menus;
  }

  setCurrent(intl, pathname) {
    const menus = this.getMenus(intl)
    menus.map((menu) => {
      if (menu.children) {
        this.setChildActive(menu, pathname)
      } else {
        if (pathname.startsWith('/mine/notice/create/')) {
          pathname = '/mine/notice'
        } else if (pathname.startsWith('/mine/advert/create/')) {
          pathname = '/mine/advert'
        } else if (pathname.startsWith('/mine/user/detail/')) {
          pathname = '/mine/user'
        }
        if (menu.url && pathToRegexp(menu.url).exec(pathname)) {
          this.setState({
            currentMenu: menu.key
          })
        }
      }
    })
    this.setState({
      menus,
    })
  }

  componentDidMount() {

  }

  setChildActive(parent, pathname) {
    const childFilter = parent.children.filter(child => {
      if (defaultPath.indexOf(pathname) > -1) {
        pathname = defaultMenu
      } else if (pathname.startsWith('/mine/user/create/')) {
        pathname = '/mine/user/admin'
      }
      if (child.match) {
        return pathToRegexp(child.match).exec(pathname)
      } else {
        return child.url && child.url.startsWith(pathname)
      }
    })
    if (childFilter.length) {
      this.setState({
        openMenu: parent.key,
        currentMenu: childFilter[0].key
      })
    }
  }

  toggleSub(e, menu, parent) {
    e.preventDefault();
    e.stopPropagation();
    if (menu.children && menu.children.length) {
      this.setState(preState => {
        return {
          openMenu: preState.openMenu === menu.key ? '' : menu.key
        }
      })
    } else {
      this.setState(preState => {
        return {
          currentMenu: menu.key,
          openMenu: parent ? parent.key : ''
        }
      })
    }
  }

  getMenuItem(menus, parent) {
    if (menus && menus.length) {
      return menus.map((menu) => {
        const authority = menu.authority
        if (menu.children) {
          if (!authority || (authority && hasAuth(authority))) {
            return (
              <li key={menu.key} className="sub_menu" onClick={(e) => this.toggleSub(e, menu, parent)}>
                <div className="parent">
                  {
                    menu.icon && <Icon type={menu.icon} />
                  }
                  <span>{menu.name}</span>
                  {
                    this.state.openMenu === menu.key ? <Icon type="caret-down" className="icon-du" />
                      : <Icon type="caret-up" className="icon-du" />
                  }
                </div>
                <ul className={this.state.openMenu === menu.key ? '' : 'menu_hidden'}>
                  {
                    this.getMenuItem(menu.children, menu)
                  }
                </ul>
              </li>
            )
          }

        } else {
          if (!authority || (authority && this.hasAuth(authority))) {
            return (
              <li key={menu.key} className={this.state.currentMenu === menu.key ? 'active' : ''} onClick={(e) => this.toggleSub(e, menu, parent)}>
                {
                  menu.icon && <Icon type={menu.icon} />
                }
                <Link to={menu.url}>{menu.name}</Link>
              </li>
            )
          }
        }
      })
    }
  }

  render() {
    const { menus } = this.state;
    return (
      <div className="aside">
        <ul>
          {
            this.getMenuItem(menus)
          }
        </ul>
      </div>
    )
  }
}

export default injectIntl(Header);